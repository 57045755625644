<template>
  <div>
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="390px"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="section">
        <div class="container">
          <v-row>
            <v-col cols="12" sm="6">
              <md-card class="bg-success">
                <md-card-content>
                  <h5 class="card-category card-category-social text-white">
                    <i class="far fa-newspaper" /> Your Shipments
                  </h5>

                  <p class="card-description text-white">
                    Send a message to one of your partners to invite them to
                    Goose. Once enrolled they will automatically be connected
                    with no application needed.
                  </p>

                  <div class="card-stats text-center"></div>
                </md-card-content>
              </md-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Search by Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="searchByDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-data-table
            v-if="$apollo.data.shipments"
            :headers="headers"
            :items="shipments"
            :sort-by="['created_at']"
            :sort-desc="[true, false]"
            class="elevation-1"
            :search="search"
          >
            <template #top>
              <v-toolbar flat>
                <v-toolbar-title>Shipments</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field
                  v-model="searchInput"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  @keyup="onSearch"
                />
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template #item="{ item }">
              <tr>
                <td>
                  {{ item.created_at | date_format }}
                </td>
                <td>
                  {{ item.identifier }}
                </td>
                <td>
                  {{ item.order.identifier }}
                </td>

                <td>
                  {{ item.order.masterOrder.buyerCompany.company_name }}
                </td>
                <td>
                  {{ item.order.po }}
                </td>
                <!-- <td>
                  <span v-if="item.status != null"> {{item.status}} </span>
                  <span v-else> Pending </span>
                </td> -->
                <td>
                  <v-icon
                    small
                    class="mr-2"
                    @click="
                      $router.push('/market/ship-detail/' + item.identifier)
                    "
                  >
                    mdi-eye
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import GetShipments from '@/graphql/queries/GetShipments.gql'

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
    fullName(user) {
      return (user.FirstName || '') + ' ' + (user.LastName || '')
    },
  },
  data: () => ({
    date: null,
    searchInput: null,
    menu2: false,
    search: '',
    sortBy: 'item.created_at',
    sortDesc: false,
    headers: [
      { text: 'Date', value: 'created_at' },
      { text: 'Shipment ID', sortable: false },
      { text: 'Order ID', sortable: false },
      { text: 'Customer', sortable: false },

      { text: 'PO #', sortable: false },
      { text: 'Actions', sortable: false },
    ],
  }),

  apollo: {
    shipments: {
      query: GetShipments,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          company: this.$store.state.auth.user.company_relation,
        }
      },
    },
  },
  methods: {
    async searchByDate() {
      this.menu2 = false
      var tomorrow = new Date(this.date)
      tomorrow.setDate(tomorrow.getDate() + 1)
      const nextDay = new Date(tomorrow).toISOString()
      await this.$apollo.queries.shipments.refetch({
        searchInput: this.searchInput,
        date: new Date(this.date).toISOString(),
        nextDay: nextDay,
      })
    },
    async onSearch() {
      await this.$apollo.queries.shipments.refetch({
        searchInput: this.searchInput,
        date: undefined,
        nextDay: undefined,
      })
    },
    toggleOrder() {
      this.sortDesc = !this.sortDesc
    },
    nextSort() {
      let index = this.headers.findIndex((h) => h.value === this.sortBy)
      index = (index + 1) % this.headers.length
      this.sortBy = this.headers[index].value
    },
  },
}
</script>

<style scoped>
.main-raised {
  margin: -120px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.main {
  background: #eee;
  position: relative;
  z-index: 3;
}
</style>

<style lang="scss"></style>
